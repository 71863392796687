import { render, staticRenderFns } from "./index.vue?vue&type=template&id=72c08ac8"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockBannerHero: require('/var/www/html/glamoraV2/frontend/components/block/banner/hero/index.vue').default,BlocksWrapper: require('/var/www/html/glamoraV2/frontend/components/blocks-wrapper/index.vue').default,BlockLauncherCarousel: require('/var/www/html/glamoraV2/frontend/components/block/launcher/carousel/index.vue').default,BlockRecirculationDiary: require('/var/www/html/glamoraV2/frontend/components/block/recirculation/diary/index.vue').default,BlockCarouselProjects: require('/var/www/html/glamoraV2/frontend/components/block/carousel/projects/index.vue').default,BlockTextBlockquote: require('/var/www/html/glamoraV2/frontend/components/block/text/blockquote/index.vue').default,Page: require('/var/www/html/glamoraV2/frontend/components/page/index.vue').default})
